import React from "react";

import "./style.css";

function getIcon(tipo) {

}

export default function List(props) {
  return (
    <div className="product-list">
      <h3><span className="product-badge" style={{ background: props.background || "#525257" }}>{props.company}</span> Cod. {props.cod}  </h3>
      <div className="product-info">
        <div className="product-seller">
          <b>Vendido por: </b> {props.staff}
        </div>
        <div className="product-date-value">
          <span className="date">{props.date}</span>
          <span className="value">{props.value}</span>
        </div>


      </div>
    </div>
  );
}

