import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { Select, MenuItem, InputLabel } from "@material-ui/core";
import { Archive, Search } from '@material-ui/icons';

import moment from 'moment';
import Swal from 'sweetalert2'


import ProductList from "components/ProductList";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


import styles from "assets/jss/material-dashboard-react/views/dashboardStyle";
import formatData from "../../utils/formatData"
import formatValue from "../../utils/formatValue"


import api from "../../services/api"
import { getUsuario, getCompany } from "../../services/auth"


class Base extends React.Component {


  constructor(props) {
    super(props)

    this.state = {
      usuario: getUsuario(),
      loading: true,
      empresaSelecionada: [getUsuario().empresas[0].codEmpresa],
      products: [],
      modalShow: false,
      produtoSelecionado: [],
      busca: '',
      cardHidden: false,
      tipoBusca: 'nome',
    }

    this.loadProducts = this.loadProducts.bind(this)
    this.buscarProduto = this.buscarProduto.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChangeCompany = this.handleChangeCompany.bind(this)
    this.selectProduct = this.selectProduct.bind(this)
    this.toggleFilter = this.toggleFilter.bind(this)

  }

  async loadProducts() {
    Swal.showLoading();
    const empresas = this.state.empresaSelecionada;
    try {
      const response = await api.get('/os', {
        params: {
          codEmpresa: empresas,
        }
      });

      if (response.data.errno) {
        Swal.close();
        Swal.fire('Erro', 'Erro na consulta - API', 'error')
        this.setState({ loading: false, products: [] })
      } else {
        this.setState({
          loading: false,
          products: response.data
        })
        Swal.close();
      }


    } catch (e) {
      Swal.close();
      this.setState({ loading: false, products: [] })
    }
  }

  async buscarProduto() {
    Swal.showLoading();
    const empresas = this.state.empresaSelecionada;
    try {
      const response = await api.get('/os/busca', {
        params: {
          codEmpresa: empresas,
          proDescricao: this.state.busca
        }
      });

      if (response.data.errno) {
        Swal.close();
        Swal.fire('Erro', 'Erro na consulta - API', 'error')
        this.setState({ loading: false, products: [] })
      } else {
        this.setState({
          loading: false,
          products: response.data
        })
        Swal.close();
      }


    } catch (e) {
      Swal.close();
      this.setState({ loading: false, products: [] })
    }
  }

  async autoload() {
    var empresasArray = []
    getUsuario().empresas.map((item, key) => {
      empresasArray.push(item.codEmpresa)
    })
    await this.setState({ empresaSelecionada: empresasArray });
    this.loadProducts()
  }

  componentDidMount() {
    this.autoload();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      var isTrueSet = (this.props.history.location.state.cardHidden === 'true');
      this.toggleFilter()
    }
  }

  async handleChangeCompany(event) {
    const value = event.target.value;
    await this.setState({ empresaSelecionada: value });
    this.loadProducts()
  }

  handleClose() {
    this.setState({ modalShow: false });
  }

  toggleFilter() {
    if (this.state.hideFilter == 'show')
      this.setState({ hideFilter: 'hide' })
    else
      this.setState({ hideFilter: 'show' })
  }

  selectProduct(item) {
    this.setState({ produtoSelecionado: item });
    const html = `
      <div align='left'>
        <h4>${item.proDescricao}</h4>
        <b>Cód.: ${item.proCodigo}</b>
        <p><b>Estoque atual:</b> ${item.proEstoqueAtual}</p>
        <p><b>Valor Custo:</b> ${formatValue(item.proCusto)}</p>
        <p><b>Valor Atacado:</b> ${formatValue(item.proAtacado)}</p>
        <p><b>Valor Venda:</b> ${formatValue(item.proVenda)}</p>
      </div>
      `

    Swal.fire('Detalhes do Serviço', html)
  }



  render() {
    const { classes } = this.props;
    return (
      <div style={{ paddingTop: "6px" }}>
        <div >
          <Card id="filter" className={this.state.hideFilter}>
            <CardBody>

              <Grid container spacing={1}>
                <Grid item md={4} xs={12}>
                  <InputLabel id="demo-simple-select-label">Selecionar Empresa</InputLabel>
                  <Select multiple={true} value={this.state.empresaSelecionada} labelId="demo-simple-select-label" style={{ width: '100%' }} onChange={this.handleChangeCompany}>
                    {!this.state.usuario ? 'Carregando...' : this.state.usuario.empresas.map((item, key) => {
                      return (
                        <MenuItem selected key={key} value={item.codEmpresa} >{item.nomeEmpresa}</MenuItem>
                      )
                    }
                    )}
                  </Select>

                </Grid>
                <Grid item md={4} xs={9}>
                  <TextField style={{ width: '100%' }} type="text" placeholder="Pesquisar..." value={this.state.busca} onChange={(e) => this.setState({ busca: e.target.value })} />
                </Grid>
                {/* <Grid item md={4} xs={4}>
                  <Select value={this.state.tipoBusca} labelId="tipobusca" style={{ width: '100%' }}  onChange={(e) => this.setState({ tipoBusca: e.target.value })}>
                    <MenuItem  key={'nome'} value={'nome'} >Nome</MenuItem>
                    <MenuItem  key={'cod'} value={'cod'} >Cód.</MenuItem>
                  </Select>
                </Grid> */}
                <Grid item md={4} xs={2}>
                  <Button variant="contained" color="primary" onClick={() => this.buscarProduto()}> <Search />  </Button>
                </Grid>
              </Grid>

            </CardBody>
          </Card>
        </div>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Produtos
              <span style={{ textAlign: 'right', float: 'right', marginLeft: '15px' }} onClick={() => window.print()}> <i className="fas fa-print"></i> </span>
              <span style={{ textAlign: 'right', float: 'right' }} onClick={this.toggleFilter}> <i className="fas fa-filter"></i> </span>
            </h4>
          </CardHeader>
          <CardBody>
            {!this.state.products ? 'Carregando...' : this.state.products.map((item, key) => {
              return (
                // <ListItem button onClick={() => this.selectProduct(item)} key={key}>
                //   <ListItemAvatar>
                //     <i class="fa fa-archive" style={{ fontSize: '25px' }}></i>
                //   </ListItemAvatar>
                //   <ListItemText>{item.proDescricao}</ListItemText>
                // </ListItem>
                <div key={key} onClick={() => this.selectProduct(item)}>
                  <ProductList cod={item.proCodigo} title={item.proDescricao}
                    value={formatValue(item.proVenda)}
                    stock={item.proEstoqueAtual}
                    company={getCompany(item.codEmpresa).sigla}
                    background={getCompany(item.codEmpresa).cor} />
                </div>
              )
            }
            )}
          </CardBody>
        </Card>

      </div>
    );
  }

}


export default withStyles(styles)(Base);
