import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  page: {
    color: '#FFF',
    border: '0',
    height: '100vh',
    with: '100%',
    margin: '0',
    display: 'flex',
    padding: '0',
    position: 'relative',
    // minHeight: '100vh',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    "&:before":{
      backgroundColor: 'rgba(0, 0, 0, 0.65)',
    },
    "&:before,&:after": {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      zIndex: 2,
      position: 'absolute',
    },
  }
});

export default loginPageStyle;
