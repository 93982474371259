/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";

// core components/views for Admin layout
import DashboardPage from "./pages/Dashboard";
import Products from "pages/Products";
import Os from "pages/Os";
import Vendas from "pages/Vendas";
import Estatisticas from "pages/Estatisticas";
import VendaItem from "pages/VendaItem";

import { Archive, Group, Work, ShoppingCart, BarChart } from "@material-ui/icons";
import Clientes from "pages/Clientes";


const dashboardRoutes = [
  {
    onlyPath: false,
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    onlyPath: false,
    path: "/products",
    name: "Produtos",
    icon: Archive,
    component: Products,
    layout: "/admin",
  },
  {
    onlyPath: false,
    path: "/os",
    name: "Serviços",
    icon: Work,
    component: Os,
    layout: "/admin",
  },
  {
    onlyPath: false,
    path: "/clientes",
    name: "Clientes",
    icon: Group,
    component: Clientes,
    layout: "/admin",
  },
  {
    onlyPath: false,
    path: "/vendas",
    name: "Vendas",
    icon: ShoppingCart,
    component: Vendas,
    layout: "/admin",
  },
  {
    onlyPath: true,
    path: "/venda/:id/:empresa",
    name: "Detalhes da Venda",
    icon: ShoppingCart,
    component: VendaItem,
    layout: "/admin",
  },
  {
    onlyPath: false,
    path: "/estatisticas",
    name: "Estatísticas",
    icon: BarChart,
    component: Estatisticas,
    layout: "/admin",
  },

];

export default dashboardRoutes;
