import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Select, MenuItem, InputLabel } from "@material-ui/core";

import { ShoppingCart } from '@material-ui/icons';

import moment from 'moment';
import Swal from 'sweetalert2'


import SalesList from "components/SalesList";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";



import styles from "assets/jss/material-dashboard-react/views/dashboardStyle";
import formatData from "../../utils/formatData"
import formatValue from "../../utils/formatValue"


import api from "../../services/api"
import { getUsuario, getCompany } from "../../services/auth"

import "./style.css"


class Base extends React.Component {


  constructor(props) {
    super(props)

    let now = new Date();
    let y = now.getFullYear();
    let month = now.getMonth();

    this.state = {
      hideFilter: 'hide',
      usuario: getUsuario(),
      loading: true,
      empresaSelecionada: [getUsuario().empresas[0].codEmpresa],
      vendas: [],
      dataInicial: formatData(new Date('2019-01-01')) || formatData(new Date(y, month, 1)),
      dataFinal: formatData(new Date('2019-02-01')) || formatData(new Date()),
      cardHidden: true,
    }

    this.loadVendas = this.loadVendas.bind(this)
    this.handleChangeCompany = this.handleChangeCompany.bind(this)
    this.handleChangeDataInicial = this.handleChangeDataInicial.bind(this)
    this.handleChangeDataFinal = this.handleChangeDataFinal.bind(this)
    this.toggleFilter = this.toggleFilter.bind(this)
  }

  async loadVendas() {
    Swal.showLoading();
    const company = this.state.empresaSelecionada;
    const dataInicial = new Date((this.state.dataInicial).replace('-', '/'));
    const dataFinal = new Date((this.state.dataFinal).replace('-', '/'));
    try {
      const response = await api.get('/venda', {
        params: {
          dataInicial: formatData(dataInicial),
          dataFinal: formatData(dataFinal),
          status: 'F',
          codEmpresa: company
        }
      });

      Swal.close();

      if (response.data.length == 0) {
        Swal.fire('', 'Vendas não encontradas para o período selecionado', 'warning')
      }

      this.setState({
        loading: false,
        vendas: response.data
      })
    } catch (e) {
      Swal.close();
      Swal.fire('Erro ao processar requisição', e.toString(), 'error')
      this.setState({
        loading: false,
        vendas: []
      })
    }

  }
  componentWillMount() {
    this.loadVendas()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      var isTrueSet = (this.props.history.location.state.cardHidden === 'true');
      this.toggleFilter()
    }
  }

  handleChangeCompany(event) {
    const value = event.target.value;
    console.log(value)
    this.setState({ empresaSelecionada: value });
  }

  handleChangeDataInicial(event) {
    const val = event.target.value;
    this.setState({ dataInicial: val });
  }

  handleChangeDataFinal(event) {
    const val = event.target.value;
    this.setState({ dataFinal: val });
  }

  toggleFilter() {
    if (this.state.hideFilter == 'show')
      this.setState({ hideFilter: 'hide' })
    else
      this.setState({ hideFilter: 'show' })
  }



  render() {
    const { classes } = this.props;
    return (
      <div style={{paddingTop: "22px"}}>
        <div >
          <Card id="filter" className={this.state.hideFilter}>
            <CardBody>

              <Grid container spacing={1}>
                <Grid item md={4} xs={12}>
                  <InputLabel id="demo-simple-select-label">Selecionar Empresa</InputLabel>
                  <Select multiple={true} value={this.state.empresaSelecionada} labelId="demo-simple-select-label" style={{ width: '100%' }} onChange={this.handleChangeCompany}>
                    {!this.state.usuario ? 'Carregando...' : this.state.usuario.empresas.map((item, key) => {
                      return (
                        <MenuItem selected key={key} value={item.codEmpresa} >{item.nomeEmpresa}</MenuItem>
                      )
                    }
                    )}
                  </Select>

                </Grid>

                <Grid item md={4} xs={6}>
                  <label>Data Inicial</label>
                  <TextField style={{ width: '100%' }} type="date" defaultValue={this.state.dataInicial} onChange={this.handleChangeDataInicial} />
                </Grid>
                <Grid item md={4} xs={6}>
                  <label>Data Final</label>
                  <TextField style={{ width: '100%' }} type="date" defaultValue={this.state.dataFinal} onChange={this.handleChangeDataFinal} />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Button style={{ width: '100%' }} variant="contained" color="primary" onClick={() => this.loadVendas()}> Pesquisar </Button>
                </Grid>
              </Grid>

            </CardBody>
          </Card>
        </div>

        
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Vendas
              <span style={{ textAlign: 'right', float: 'right', marginLeft: '15px'  }} onClick={()=> window.print()}> <i className="fas fa-print"></i> </span>              
              <span style={{ textAlign: 'right', float: 'right'}} onClick={this.toggleFilter}> <i className="fas fa-filter"></i> </span>
            </h4>
          </CardHeader>
          <CardBody>

            {!this.state.vendas ? 'Carregando...' : this.state.vendas.map((item, key) => {
              return (
                <div onClick={() => window.location.href = `/admin/venda/${item.vedCod}/${item.codEmpresa}`}>
                  <SalesList cod={item.vedCod} staff={item.nomeAtendente}
                    value={formatValue(item.vedTotalProdLiquido)}
                    type="credit" client={item.vedCliNome}
                    date={moment(item.vedFechamento).format("DD/MM/YYYY [às] HH:mm")} company={getCompany(item.codEmpresa).sigla} background={getCompany(item.codEmpresa).cor} />
                </div>
              )
            }
            )}

          </CardBody>
        </Card>

      </div>
    );
  }

}


export default withStyles(styles)(Base);
