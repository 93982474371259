import React from "react";

import "./style.css";


export default function List(props) {
  return (
    <div className="product-list">
      <h3><span className="product-badge" style={{ background: props.background || "#2484aa" }}>{props.company}</span> {props.title}</h3>
      <div className="product-info">
        <div className="product-left">
          <b>Cod.:</b> {props.cod}
          <br></br>
          <b>Estoque atual:</b> {props.stock}
        </div>

        <div className="product-right">
          <span>{props.value}</span>
        </div>
      </div>
    </div>
  );
}

