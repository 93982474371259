import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Swal from 'sweetalert2'

import { Select, MenuItem, InputLabel, } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { Group, LocalAtm, MonetizationOn } from "@material-ui/icons";

import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle";
import formatData from "../../utils/formatData"
import formatValue from "../../utils/formatValue"


import api from "../../services/api"
import { getUsuario } from "../../services/auth"

import "./style.css"


function getIcon(tabela) {
  var icone = "";
  switch (tabela) {
    case 'venda':
      icone = 'fa-shopping-cart'
      break;
    case 'contaMov':
      icone = 'fas-wallet'
      break;

    default:
      icone = 'fas-cart';
  }
  return icone;
}

class Base extends React.Component {


  constructor(props) {
    super(props)

    this.state = {
      usuario: getUsuario(),
      loading: true,
      empresaSelecionada: props.match.params.empresa,
      vendaCod: props.match.params.id,
      venda: [],
      items: [],
      pagamento: [],
    }

    this.loadVendaItem = this.loadVendaItem.bind(this)
  }


  async loadVendaItem() {
    Swal.showLoading();
    const company = this.state.empresaSelecionada;
    const vedCod = this.state.vendaCod
    try {
      const response = await api.get('/venda/item', {
        params: {
          vedCod: vedCod,
          codEmpresa: company,
        }
      });
      this.setState({
        loading: false,
        items: response.data.items,
        venda: response.data.venda,
        pagamento: response.data.pagamento
      })
      Swal.close();
    } catch (e) {
      Swal.close();
      this.setState({
        loading: false,
        items: null,
        venda: null,
        pagamento: null
      })
    }
  }

  componentDidMount() {
    this.loadVendaItem()
  }


  render() {
    const { classes } = this.props;
    return (
      <div>
        <br></br>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Detalhes da venda <span style={{ textAlign: 'right', float: 'right' }} onClick={() => window.print()}> <i className="fas fa-print"></i>  </span></h4>
          </CardHeader>
          <CardBody>

            <div className="result">
              <div className="venda">
                <div className="cliente">
                  <h3>cliente</h3>
                  <span>{this.state.venda.vedCliNome ? this.state.venda.vedCliNome : ''}</span>
                </div>

                <div className="cliente">
                  <h3>atendente</h3>
                  <span>{this.state.venda.nomeAtendente ? this.state.venda.nomeAtendente : ''}</span>
                </div>

                <div className="valor">
                  <div className="left">
                    total da venda
                  </div>
                  <div className="right">
                    {this.state.venda.vedTotalProdLiquido ? formatValue(this.state.venda.vedTotalProdLiquido) : ''}
                  </div>
                </div>

                <h6 className="title">ITENS</h6>

                <div className="itens">

                  {!this.state.items ? 'Carregando...' : this.state.items.map((item, key) => {
                    return (
                      <div className="item" key={key}>
                        <div className="produto"><b>{item.vdiQtde}x</b> {item.vdiDescricao}</div>
                        <div className="preco" >{formatValue(item.vdiValorTotalLiq)}</div>
                      </div>
                    )
                  }
                  )}
                  <div className="item" key={'total'}>
                    <div className="produto"><b>Total</b></div>
                    <div className="preco" style={{fontSize: '15px', color: 'green', fontWeight: 'bold'}}>{this.state.venda.vedTotalProdLiquido ? formatValue(this.state.venda.vedTotalProdLiquido) : ''}</div>
                  </div>

                </div>
                <hr />
                <h6 className="title">FORMA DE PAGAMENTO</h6>
                <hr />

                {!this.state.pagamento ? 'Carregando...' : this.state.pagamento.map((item, key) => {
                  return (
                    <div className="pagamento" key={key}>
                      <span className="tipoPagamento">{item.pgtTipoDesc}</span>
                      <span className="valorPagamento">{formatValue(item.pgtvalor)}</span>
                    </div>
                  )
                }
                )}

              </div>

            </div>

          </CardBody>
        </Card>

      </div>
    );
  }

}


export default withStyles(styles)(Base);
