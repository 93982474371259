/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";

import { Dashboard, Archive, Group, Work, ShoppingCart, BarChart } from "@material-ui/icons";

import "./style.css";

const isActive = (path) => {
  if (path === window.location.pathname)
    return "nav__link--active";
  else
    return '';
};

export default function Footer(props) {

  return (
    <footer >
      <nav className="nav">
        <a href="/admin/dashboard" className={'nav__link ' + isActive('/admin/dashboard')}>
          <Dashboard />
          <span className="nav__text">Dashboard</span>
        </a>
        <a href="/admin/estatisticas" className={'nav__link ' + isActive('/admin/estatisticas')}>
          <BarChart />
          <span className="nav__text">Estatisticas</span>
        </a>
        <a href="/admin/products" className={'nav__link ' + isActive('/admin/products')}>
          <Archive />
          <span className="nav__text">Produtos</span>
        </a>
        <a href="/admin/clientes" className={'nav__link ' + isActive('/admin/clientes')}>
          <Group />
          <span className="nav__text">Clientes</span>
        </a>
        <a href="/admin/vendas" className={'nav__link ' + isActive('/admin/vendas')}>
          <ShoppingCart />
          <span className="nav__text">Vendas</span>
        </a>
      </nav>
    </footer>
  );
}

Footer.contextTypes = {
  router: PropTypes.object
};