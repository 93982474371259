import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Swal from 'sweetalert2'

import { Select, MenuItem, InputLabel, } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { Group, LocalAtm, MonetizationOn } from "@material-ui/icons";

import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle";

import formatData from "../../utils/formatData"
import formatValue from "../../utils/formatValue"


import api from "../../services/api"
import { getUsuario } from "../../services/auth"

import "./style.css"
import { Hidden } from '@material-ui/core/Hidden';


function getIcon(tabela) {
  var icone = "";
  switch (tabela) {
    case 'venda':
      icone = 'fa-shopping-cart'
      break;
    case 'contaMov':
      icone = 'fas-wallet'
      break;

    default:
      icone = 'fas-cart';
  }
  return icone;
}

var DefaultCompany = 0;
if (getUsuario()) {
  DefaultCompany = getUsuario().empresas[0].codEmpresa
}

class Base extends React.Component {


  constructor(props) {
    super(props)

    this.state = {
      usuario: getUsuario(),
      loading: true,
      empresaSelecionada: getUsuario().empresas[0].codEmpresa,
      contasPagar: [],
      dashboard: [],
      eventos: [],
      cardHidden: true,
    }

    this.loadEventos = this.loadEventos.bind(this)
    this.handleChangeCompany = this.handleChangeCompany.bind(this)
  }




  async loadEventos() {
    Swal.showLoading();
    const company = this.state.empresaSelecionada;
    try {
      const response = await api.get('/eventos', {
        params: {
          codEmpresa: company,
        }
      });
      this.setState({
        loading: false,
        eventos: response.data
      })
      Swal.close();
    } catch (e) {
      Swal.close();
      this.setState({
        loading: false,
        eventos: null
      })
    }
  }

  componentDidMount() {
    this.loadEventos()

  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      var isTrueSet = (this.props.history.location.state.cardHidden === 'true');
      this.setState({ cardHidden: isTrueSet })
    }
  }

  async handleChangeCompany(event) {
    const value = event.target.value;
    await this.setState({ empresaSelecionada: value });
    this.loadEventos(value)
  }



  render() {
    const { classes } = this.props;


    console.log("propsComponent: ", this.state.cardHidden)
    return (
      <div>
        <Card >
          <CardBody hidden={this.state.cardHidden}>
            <InputLabel id="demo-simple-select-label">Selecionar Empresa</InputLabel>
            <Select MenuProps={{
              className: classes.selectMenu
            }}
              classes={{
                select: classes.select
              }} labelId="demo-simple-select-label" style={{ width: '100%' }} onChange={this.handleChangeCompany} defaultValue={DefaultCompany}>
              {!this.state.usuario ? 'Carregando...' : this.state.usuario.empresas.map((item, key) => {
                return (
                  <MenuItem value={item.codEmpresa} key={key} >{item.nomeEmpresa}</MenuItem>
                )
              }
              )}
            </Select>
          </CardBody>
        </Card>

        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Eventos</h4>

          </CardHeader>
          <CardBody>
            <div className="list">
              {!this.state.eventos ? 'Carregando...' : this.state.eventos.map((item, key) => {
                return (
                  <div className="item" key={key} onClick={() => window.location.href = `/admin/venda/${item.item_id}/${item.codEmpresa}`}>
                    <div className="icon">
                      <i className={'fas ' + getIcon(item.tabela)} ></i>
                    </div>
                    <div className="details">
                      <p dangerouslySetInnerHTML={{ __html: item.descricao }}></p>
                      {/* <span>{moment(item.data).format('DD/MM/YYYY [às] HH:MM:SS')}</span> */}
                    </div>
                  </div>
                )
              }
              )}


            </div>
          </CardBody>
        </Card>

      </div>
    );
  }

}


export default withStyles(styles)(Base);
