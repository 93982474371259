import React from "react";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";



// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

import Logo from "../../assets/img/logo2.png";
import LoginBg from "../../assets/img/login.jpg";

import api from "../../services/api";
import { login, usuario, getToken } from "../../services/auth";

import Swal from 'sweetalert2'

const useStyles = makeStyles(styles);

export default function LoginPage() {

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");


  function isAuth() {
    let token = getToken()
    if (token) {
      location = "admin/dashboard";
    }
  }



  const handleSignIn = async e => {
    e.preventDefault();
    Swal.showLoading();
    if (!email || !password) {
      Swal.close();
      Swal.fire('', 'Preencha seu e-mail e senha para continuar!', 'info')
    } else {
      try {
        const response = await api.post("/usuario/login", { email, senha: password });
        
        if (response.data.error) {
          Swal.close();
          Swal.fire("", response.data.message , 'warning')
        } else {
          login(response.data.token);
          usuario(response.data);
          location = "admin/dashboard";
        }

      } catch (err) {
        console.log(err)
        Swal.close();
        Swal.fire("Erro", "Houve um problema com o login, verifique suas credenciais. <br><hr />" , 'error')
      }
    }
  };

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();
  return (
    <div className={classes.page} style={{ backgroundImage: `url(${LoginBg})` }}>

      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  style={{ backgroundImage: 'linear-gradient(to left,#0575e6,#021b79)' }}
                >
                  <h4 className={classes.cardTitle}>
                    <img src={Logo} width="80%" />
                  </h4>
                </CardHeader>
                <CardBody>

                  <CustomInput
                     labelText="E-mail..."
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => setEmail(e.target.value),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Senha"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}

                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                      onChange: (e) => setPassword(e.target.value),
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="info" block round onClick={handleSignIn}>
                    Entrar
                  </Button>

                </CardFooter>

                <div align="center">
                  <Button color="warning" block simple onClick={() => window.open("https://crm.inorte.com.br", '_blank')}>
                  Suporte
                  </Button>
 
                </div>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    </div>

  );
}
 // export default withStyles(styles)(SignIn);

// export default SignIn;