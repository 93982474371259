export const TOKEN_KEY = "igestor-token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const usuario = usu => {
  localStorage.setItem("igestor-usuario", JSON.stringify(usu));
};

export const getUsuario = () => JSON.parse(localStorage.getItem("igestor-usuario"));

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem("igestor-usuario");
};

const sigla = (text) => {
  if (text.split(/\s/).length == 1) {
    return text.slice(0,3).toUpperCase();
  } else {
    return text
      .split(/\s/)
      .reduce((accumulator, word) => accumulator + word.charAt(0), '')
      .toUpperCase();
  }

}

export const getCompany = (cod) => {
  const companies = getUsuario().empresas;
  const search = companies.findIndex(x => x.codEmpresa === cod);
  const result = {
    codEmpresa: companies[search].codEmpresa,
    nomeEmpresa: companies[search].nomeEmpresa,
    sigla: sigla(companies[search].nomeEmpresa),
    cor: companies[search].cor
  }
  return result;
}