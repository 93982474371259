import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Select, MenuItem, InputLabel, AppBar, Tabs, Tab, Box, Typography } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { ShoppingCart, PieChartOutlined, MonetizationOn } from '@material-ui/icons';

import moment from 'moment';
import Swal from 'sweetalert2'


import ChartistGraph from "react-chartist";

import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';


import Table from "components/Table/Table.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js"
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";


import styles from "assets/jss/material-dashboard-react/views/dashboardStyle";
import formatData from "../../utils/formatData"
import formatValue from "../../utils/formatValue"


import api from "../../services/api"
import { getCompany, getUsuario } from "../../services/auth"



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function convertMes(m) {
  let meses = ['', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  return meses[m];
}

function convertMes2(m) {
  let meses = ['', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  return meses[m];
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class Base extends React.Component {


  constructor(props) {
    super(props)

    let now = new Date();
    let y = now.getFullYear();
    let month = now.getMonth();

    const year = (new Date()).getFullYear();
    this.years = Array.from(new Array(5), (val, index) => year - index);

    this.state = {
      usuario: getUsuario(),
      loading: true,
      empresaSelecionada: [getUsuario().empresas[0].codEmpresa],
      list: [],
      listAno: {
        grafico: [],
        relatorio: [],
        totais: []
      },
      tabelaMes: [],
      value: 0,
      dataInicial: formatData(new Date(y, month, 1)),
      dataFinal: formatData(new Date()),
      mes: `${year}-${("0" + month).slice(-2)}`,
      ano: year,
      totalBruto: 0.00,
      totalLiquido: 0.00,
      mesLiquido: 0.00,
      mesBruto: 0.00,
      mesQtd: 0,
      grafico: [],
      graficoMes: [],
    }

    this.buscaDia = this.buscaDia.bind(this)
    this.buscaAno = this.buscaAno.bind(this)
    this.buscaMes = this.buscaMes.bind(this)
    this.handleChangeCompany = this.handleChangeCompany.bind(this)
    this.handleChangeYear = this.handleChangeYear.bind(this)
    this.handleChangeMes = this.handleChangeMes.bind(this)
  }

  async buscaDia() {
    Swal.showLoading();
    const company = this.state.empresaSelecionada;
    const dataInicial = new Date((this.state.dataInicial).replace('-', '/'));
    const dataFinal = new Date((this.state.dataFinal).replace('-', '/'));
    try {
      const response = await api.get('/estatistica/dia', {
        params: {
          dataInicial: formatData(dataInicial),
          dataFinal: formatData(dataFinal),
          codEmpresa: company
        }
      });

      Swal.close();

      if (response.data.length == 0) {
        Swal.fire('', 'Dados não encontradas para o período selecionado', 'warning')
      }

      var bruto = 0;
      var liquido = 0;
      const vendas = response.data;
      for (let x in vendas) {
        bruto += vendas[x].vedTotalProdBruto;
        liquido += vendas[x].vedTotalProdLiquido;
      }

      this.setState({
        loading: false,
        list: response.data,
        totalBruto: bruto.toFixed(2),
        totalLiquido: liquido.toFixed(2)
      })
    } catch (e) {
      Swal.close();
      Swal.fire('Erro ao processar requisição', e.toString(), 'error')
      this.setState({
        loading: false,
        list: []
      })
    }
  }

  async buscaAno() {
    Swal.showLoading();
    const company = this.state.empresaSelecionada;
    const ano = this.state.ano
    try {
      const response = await api.get('/estatistica/ano', {
        params: {
          ano: ano,
          codEmpresa: company
        }
      });

      Swal.close();

      if (response.data.length == 0) {
        Swal.fire('', 'Dados não encontradas para ' + ano, 'warning')
      } else {


        const results = response.data.result.relatorio;
        var relatorio = new Array()
        var j = 0;
        var pieChart = new Array()

        for (let x in results) {
          for (let i in results[x]) {
            relatorio[j] = [convertMes2(results[x][i].mes), results[x][i].quantidade, formatValue(results[x][i].totalBruto), getCompany(results[x][i].codEmpresa).sigla]

            pieChart.push({
              name: getCompany(results[x][i].codEmpresa).nomeEmpresa,
              value: results[x][i].totalBruto || 0.00,
              color: getCompany(results[x][i].codEmpresa).cor
            })

            j++;
          }
        }


        const data = {
          totais: response.data.result.totais,
          relatorio: relatorio,
          grafico: pieChart
        }

        console.log(response.data.result.totais)

        this.setState({
          loading: false,
          listAno: data
        })


      }



    } catch (e) {
      Swal.close();
      Swal.fire('Erro ao processar requisição', e.toString(), 'error')
      this.setState({
        loading: false,
        listAno: []
      })
    }
  }

  async buscaMes() {
    Swal.showLoading();
    const company = this.state.empresaSelecionada;
    const mes = this.state.mes
    try {
      const response = await api.get('/estatistica/mes', {
        params: {
          mes: mes,
          codEmpresa: company
        }
      });

      Swal.close();

      if (!response.data) {
        Swal.fire('', 'Dados não encontradas para ' + mes, 'warning')
      }


      var bruto = 0.00;
      var liquido = 0.00;
      var qtd = 0;
      var pieChart = [];
      var tabela = [];

      response.data.result.map((item, key) => {
        bruto = bruto + item.totalBruto
        liquido = liquido + item.totalLiquido
        qtd = qtd + item.quantidade

        tabela.push([item.dia, item.quantidade, formatValue(item.totalBruto), getCompany(item.codEmpresa).sigla])

        pieChart.push({
          name: getCompany(item.codEmpresa).nomeEmpresa,
          value: item.totalBruto || 0.00,
          color: getCompany(item.codEmpresa).cor
        })
      })


      this.setState({
        loading: false,
        tabelaMes: tabela,
        mesBruto: bruto,
        mesLiquido: liquido,
        mesQtd: qtd,
        graficoMes: pieChart
      })

    } catch (e) {
      Swal.close();
      Swal.fire('Erro ao processar requisição', e.toString(), 'error')
      this.setState({ loading: false })
    }
  }

  async autoload() {
    var empresasArray = []
    getUsuario().empresas.map((item, key) => {
      empresasArray.push(item.codEmpresa)
    })
    await this.setState({ empresaSelecionada: empresasArray });
  }

  componentDidMount() {
    alert("..... PÁGINA EM DESENVOLVIMENTO ..... ")
    this.autoload();
  }

  handleChangeCompany(event) {
    const value = event.target.value;
    console.log(value)
    this.setState({ empresaSelecionada: value });
  }

  handleChangeYear(event) {
    const val = event.target.value;
    this.setState({ ano: val })
  }

  async handleChangeMes(event) {
    const val = event.target.value;
    await this.setState({ mes: val })

    this.buscaMes()
  }



  render() {
    const { classes } = this.props;

    const handleChange = (event, newValue) => {
      this.setState({ value: newValue });
    };



    return (
      <div>

        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Estatísticas</h4>

          </CardHeader>
          <CardBody>

            <AppBar position="static">
              <Tabs value={this.state.value} onChange={handleChange} aria-label="simple tabs example" centered>
                <Tab label="Dia" {...a11yProps(0)} />
                <Tab label="Mês" {...a11yProps(1)} />
                <Tab label="Ano" {...a11yProps(2)} />
              </Tabs>
            </AppBar>

            <TabPanel value={this.state.value} index={0}>

              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              <Select multiple={true} value={this.state.empresaSelecionada} labelId="demo-simple-select-label" style={{ width: '100%' }} onChange={this.handleChangeCompany}>
                {!this.state.usuario ? 'Carregando...' : this.state.usuario.empresas.map((item, key) => {
                  return (
                    <MenuItem selected key={key} value={item.codEmpresa} >{item.nomeEmpresa}</MenuItem>
                  )
                }
                )}
              </Select>

              <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                  <p>Data Inicial</p>
                  <TextField style={{ width: '100%' }} type="date" defaultValue={this.state.dataInicial} onChange={this.handleChangeDataInicial} />
                </Grid>
                <Grid item md={4} xs={12}>
                  <p>Data Final</p>
                  <TextField style={{ width: '100%' }} type="date" defaultValue={this.state.dataFinal} onChange={this.handleChangeDataFinal} />
                </Grid>
                <Grid item md={4} xs={12}>
                  <p> <br></br></p>
                  <Button style={{ width: '100%' }} variant="contained" color="primary" onClick={() => this.buscaDia()}> Pesquisar </Button>
                </Grid>
              </Grid>

              {!this.state.list ? ' ' :
                <>

                  <Grid container spacing={4}>
                    <Grid item md={4}>
                      <b>Total bruto</b>
                      <p>{formatValue(this.state.totalBruto)}</p>
                    </Grid>
                    <Grid item md={4}>
                      <b>Total líquido</b>
                      <p>{formatValue(this.state.totalLiquido)}</p>
                    </Grid>
                  </Grid>
                </>
              }

              <List>
                {!this.state.list ? 'Carregando...' : this.state.list.map((item, key) => {
                  return (
                    <ListItem button onClick={() => console.log(item)} key={key}>
                      <ListItemAvatar>
                        <span style={{ background: getCompany(item.codEmpresa).cor, color: "white", padding: 4, fontSize: '10px' }}>{getCompany(item.codEmpresa).sigla}</span>
                      </ListItemAvatar>
                      <ListItemText primary={moment(item.vedFechamento).format("DD/MM/YYYY - hh:mm")} secondary={formatValue(item.vedValor)} />
                    </ListItem>
                  )
                }
                )}
              </List>



            </TabPanel>

            {/* --------------------------- TAB MES -----------------------------*/}
            <TabPanel value={this.state.value} index={1}>
              <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                  <p>Selecione a empresa</p>
                  <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
                  <Select multiple={true} value={this.state.empresaSelecionada} labelId="demo-simple-select-label" style={{ width: '100%' }} onChange={this.handleChangeCompany}>
                    {!this.state.usuario ? 'Carregando...' : this.state.usuario.empresas.map((item, key) => {
                      return (
                        <MenuItem selected key={key} value={item.codEmpresa} >{item.nomeEmpresa}</MenuItem>
                      )
                    }
                    )}
                  </Select>
                </Grid>
                <Grid item md={4} xs={12}>
                  <p>Mês</p>
                  <TextField style={{ width: '100%' }} type="month" defaultValue={this.state.mes} onChange={this.handleChangeMes} />
                </Grid>

                <Grid item md={4} xs={12}>
                  <p> <br></br></p>
                  <Button style={{ width: '100%' }} variant="contained" color="primary" onClick={() => this.buscaMes()}> Pesquisar </Button>
                </Grid>

              </Grid>

              <p></p>

              {!this.mesBruto && this.mesBruto <= 0 ? ' ' :
                <>
                  <GridContainer>

                    <GridItem xs={12} sm={6} md={4}>
                      <Card>
                        <CardHeader color="warning" stats icon>
                          <CardIcon color="warning">
                            <MonetizationOn />
                          </CardIcon>
                          <p className={classes.cardCategory}>Total Bruto</p>
                          <h3 className={classes.cardTitle}>
                            {this.state.mesBruto > 0 ? formatValue(this.state.mesBruto) : ''}
                          </h3>
                        </CardHeader>
                      </Card>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={4}>
                      <Card>
                        <CardHeader color="primary" stats icon>
                          <CardIcon color="primary">
                            <MonetizationOn />
                          </CardIcon>
                          <p className={classes.cardCategory}>Total Líquido</p>
                          <h3 className={classes.cardTitle}>
                            {this.state.mesLiquido > 0 ? formatValue(this.state.mesLiquido) : ''}
                          </h3>
                        </CardHeader>
                      </Card>
                    </GridItem>


                    <GridItem xs={12} sm={6} md={4}>
                      <Card>
                        <CardHeader color="info" stats icon>
                          <CardIcon color="info">
                            <ShoppingCart />
                          </CardIcon>
                          <p className={classes.cardCategory}>Qtd. de Vendas</p>
                          <h3 className={classes.cardTitle}>
                            {this.state.mesQtd > 0 ? this.state.mesQtd : ''}
                          </h3>
                        </CardHeader>
                      </Card>
                    </GridItem>


                    <GridItem xs={12} sm={12} md={6}>
                      <Card>
                        <CardHeader color="danger" icon>
                          <CardIcon color="danger">
                            <PieChartOutlined />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Gráfico bruto do mês</h4>
                        </CardHeader>
                        <CardBody>
                          <PieChart width={400} height={400}>
                            <Pie
                              data={this.state.graficoMes}
                              labelLine={false}
                              outerRadius={80}
                              dataKey="value"
                              fill="#8884d8"
                              label={renderCustomizedLabel}
                            >
                              {this.state.graficoMes.map((item, index) => (
                                <Cell key={`cell-${index}`} fill={item.color} />
                              ))}
                            </Pie>
                          </PieChart>
                        </CardBody>
                        <CardFooter stats className={classes.cardFooter}>
                          <h6 className={classes.legendTitle}>Legenda:</h6>

                          {!this.state.usuario ? 'Carregando...' : this.state.usuario.empresas.map((item, key) => {
                            return (
                              <>
                                <i className={"fa fa-circle "} style={{ color: item.cor }} ></i> {item.nomeEmpresa}  {` `}
                              </>
                            )
                          }
                          )}

                        </CardFooter>
                      </Card>
                    </GridItem>

                    <Card>
                      <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Vendas por dia</h4>

                      </CardHeader>
                      <CardBody>
                        {
                          !this.state.tabelaMes ? 'Carregando...' :
                            <Table
                              tableHeaderColor="primary"
                              tableHead={["Dia", "Qtde.", "Faturamento", "Loja"]}
                              tableData={this.state.tabelaMes}
                            />
                        }

                      </CardBody>
                    </Card>


                  </GridContainer>
                </>
              }
            </TabPanel>


            {/* --------------------------------- TAB ANO ------------------------------------------ */}

            <TabPanel value={this.state.value} index={2}>
              <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                  <p>Selecione a empresa</p>
                  <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
                  <Select multiple={true} value={this.state.empresaSelecionada} labelId="demo-simple-select-label" style={{ width: '100%' }} onChange={this.handleChangeCompany}>
                    {!this.state.usuario ? 'Carregando...' : this.state.usuario.empresas.map((item, key) => {
                      return (
                        <MenuItem selected key={key} value={item.codEmpresa} >{item.nomeEmpresa}</MenuItem>
                      )
                    }
                    )}
                  </Select>
                </Grid>
                <Grid item md={4} xs={12}>
                  <p>Ano</p>
                  <Select labelId="ano-simple-select-label" style={{ width: '100%' }} onChange={this.handleChangeYear} defaultValue={this.years[0]}>
                    {
                      this.years.map((year, index) => {
                        return <MenuItem value={year} key={index}>{year}</MenuItem>
                      })
                    }
                  </Select>
                </Grid>
                <Grid item md={4} xs={12}>
                  <p> <br></br></p>
                  <Button style={{ width: '100%' }} variant="contained" color="primary" onClick={() => this.buscaAno()}> Pesquisar </Button>
                </Grid>
              </Grid>

              {!this.state.listAno.totais.bruto ? ' ' :
                <>
                  <GridContainer>

                    <GridItem xs={12} sm={12} md={4}>
                      <Card>
                        <CardHeader color="warning" stats icon>
                          <CardIcon color="warning">
                            <MonetizationOn />
                          </CardIcon>
                          <p className={classes.cardCategory}>Total Bruto</p>
                          <h6 className={classes.cardTitle}>
                            {this.state.listAno.totais ? formatValue(this.state.listAno.totais.bruto) : ''}
                          </h6>
                        </CardHeader>
                      </Card>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={4}>
                      <Card>
                        <CardHeader color="primary" stats icon>
                          <CardIcon color="primary">
                            <MonetizationOn />
                          </CardIcon>
                          <p className={classes.cardCategory}>Total Líquido</p>
                          <h6 className={classes.cardTitle}>
                            {this.state.listAno.totais ? formatValue(this.state.listAno.totais.liquido) : ''}
                          </h6>
                        </CardHeader>
                      </Card>
                    </GridItem>


                    <GridItem xs={12} sm={6} md={4}>
                      <Card>
                        <CardHeader color="info" stats icon>
                          <CardIcon color="info">
                            <ShoppingCart />
                          </CardIcon>
                          <p className={classes.cardCategory}>Qtd. de Vendas</p>
                          <h6 className={classes.cardTitle}>
                            {this.state.listAno.totais ? this.state.listAno.totais.quantidade : ''}
                          </h6>
                        </CardHeader>
                      </Card>
                    </GridItem>


                    <GridItem xs={12} sm={12} md={6}>
                      <Card>
                        <CardHeader color="danger" icon>
                          <CardIcon color="danger">
                            <PieChartOutlined />
                          </CardIcon>
                          <h4 style={{ color: 'black' }}>Gráfico faturamento do ano</h4>
                        </CardHeader>
                        <CardBody>
                          <PieChart width={400} height={400}>
                            <Pie
                              data={this.state.listAno.grafico}
                              labelLine={false}
                              outerRadius={100}
                              dataKey="value"
                              fill="#8884d8"
                            // label={renderCustomizedLabel}
                            >
                              {this.state.listAno.grafico.map((item, index) => (
                                <Cell key={`celula-${index}`} fill={item.color} />
                              ))}
                            </Pie>
                          </PieChart>
                        </CardBody>
                        <CardFooter stats className={classes.cardFooter}>
                          <h6 className={classes.legendTitle}>Legenda:</h6>

                          {!this.state.usuario ? 'Carregando...' : this.state.usuario.empresas.map((item, key) => {
                            return (
                              <>
                                <i className={"fa fa-circle "} style={{ color: item.cor }} ></i> {item.nomeEmpresa}  {` `}
                              </>
                            )
                          }
                          )}

                        </CardFooter>
                      </Card>
                    </GridItem>

                    <Card>
                      <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Vendas por mês</h4>

                      </CardHeader>
                      <CardBody>
                        {
                          !this.state.listAno.relatorio ? 'Carregando...' :
                            <Table
                              tableHeaderColor="primary"
                              tableHead={["Mês", "Qtde.", "Faturamento", "Loja"]}
                              tableData={this.state.listAno.relatorio}
                            />
                        }

                      </CardBody>
                    </Card>

                  </GridContainer>
                </>
              }


            </TabPanel>



          </CardBody>
        </Card>

      </div >
    );
  }

}


export default withStyles(styles)(Base);
